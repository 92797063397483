import { Ugla3D, UnityViewerBridge, useUgla3dStateManager, Viewer } from "@ugla-france/ugla-3d-library";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { MultiBackend } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'

import useStorageProvider from "../providers/storageprovider";
import SelectionPanel from "../components/panels/SelectionPanel";
import ViewerPanel from "../components/panels/ViewerPanel";
import ColorsPanel from "../components/panels/ColorsPanel";
import Tabs from "../components/controls/Tabs";
import BasketPanel from "../components/panels/BasketPanel";
import SaveButton from "../components/atoms/SaveButton";
import Navigation from "../components/panels/Navigation";
import Layout from "../layout/Layout";
import ToolsPanel from "../components/panels/ToolsPanel";
import FurniturePanel from "../components/panels/subpanels/FurniturePanel";
import RenderSubpanel from "../components/panels/subpanels/RenderSubpanel";
import LightsSubpanel from "../components/panels/subpanels/LightsSubpanel";

export const bridge : {current : UnityViewerBridge | null} = {
  current : null
};

const EditorPage : React.FC<{}> = () => {
  const stateManager = useUgla3dStateManager();
  const storageProvider = useStorageProvider();
  const [selectedTool, setSelectedTool] = useState<string>('Mobilier');
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const viewer = searchParams.get('viewer');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

  useEffect(() => {
    if(viewer) {
      if(bridge.current?.running) {
        bridge.current.stop();
        bridge.current = null;
      }

      bridge.current = new UnityViewerBridge(stateManager, {viewerUrl : viewer, appName : 'demo', mode : '3d'})
      bridge.current.start();
    }
  }, [viewer])

  useEffect(() => {
    if(key) {
      storageProvider.getOne(key).then(
        model => stateManager.setModel(new Ugla3D(JSON.stringify(model)))
      );
    }
  }, [key])


  return (
    <Layout
      leftPanel={
        <Navigation />
      }
      rightPanel={
        <ToolsPanel selected={selectedTool} setSelected={setSelectedTool} />
      }
      bottomPanel={
        selectedTool === 'Mobilier' ? <FurniturePanel /> :
        selectedTool === 'Rendu' ? <RenderSubpanel /> :
        selectedTool === 'Eclairages' ? <LightsSubpanel backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} /> :
        null
      }
    >
      <ViewerPanel backgroundColor={backgroundColor} />
    </Layout>
  )
}

export default EditorPage;


const Container = styled.div`
  position : relative;
  width : 100vw;
  height : 100vh;
  display: flex;
`

const LeftPanel = styled.div`
  height : 100%;
  width : 35rem;
  border-right : 0.3rem solid #EEE;
  display: flex;
  flex-direction: column;
`

const LeftTabsContainer = styled.div`
  flex : 1;
  overflow: auto;
`

const LeftButtonContainer = styled.div`
  padding : 1rem 2rem 2rem 2rem;
  display: flex;
`

const RightPanel = styled.div`
  flex : 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const CenterPanel = styled.div`
  flex : 1;
`

const BottomPanel = styled.div`
  height : 20rem;
  border-top : 0.3rem solid #EEE;
`
