import { useEffect, useState } from "react";
import styled from "styled-components";
import useStorageProvider, { StorageFile } from "../providers/storageprovider";
import FileCard from "../components/atoms/FileCard";
import typography from "../contants/typography";

const FileExplorerPage : React.FC<{}> = () => {
  const [files, setFiles] = useState<StorageFile[]>([]);
  const storageProvider = useStorageProvider();
  useEffect(() => {
    storageProvider.listAll()
      .then(files => setFiles(files.filter(f => !f.directory)))
      .catch(() => {
        alert('Erreur de lecture des fichies')
      })
  }, [])

  return (
    <Container>
      <Title>Dossiers</Title>
      {
        files.map(file => (
          <FileCard key={file.key} file={file} />
        ))
      }
    </Container>
  )
}

export default FileExplorerPage;


const Container = styled.div`
  position : relative;
  width : 100vw;
  height : 100vh;
  padding : 4rem;
`

const Title = styled.div`
  ${typography.title}
`