import styled from "styled-components";
import colors from "../../contants/colors";

interface IconRadioGroupProps {
  options : {
    label : string;
    icon : React.ReactNode;
    offIcon ?: React.ReactNode;
    color ?: string;
  }[];
  value : number;
  onChange : (value : number) => void;
}

const IconRadioGroup : React.FC<IconRadioGroupProps> = (p) => {
  return (
    <Container>
      {
        p.options.map((option, index) => (
          <OptionContainer key={index} onClick={() => p.onChange(index)} >
            <OptionLabel>{option.label}</OptionLabel>
            <Option $color={option.color || colors.negativeText} className={p.value === index ? 'active' : ''}>
              {p.value === index ? option.icon : (option.offIcon || option.icon)}
            </Option>
          </OptionContainer>
        ))
      }
    </Container>
  )
}

export default IconRadioGroup;

const Container = styled.div`
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : space-between;
  margin-bottom : 2rem;
`

const OptionContainer = styled.div`
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  margin-right : 1rem;

  &:last-child {
    margin-right : 0;
  }
`

const Option = styled.div<{ $color : string }>`
  display : flex;
  align-items : center;
  justify-content : center;
  width : 4rem;
  height : 4rem;
  border-radius : 50%;
  background-color : ${colors.backgroundMedium};
  color : ${props => props.$color};
  cursor : pointer;

  & svg {
    width : 3rem;
    height : 3rem;
  }

  &.active {
    background-color : ${props => props.$color};
    color : ${colors.backgroundDark};
  }
`

const OptionLabel = styled.div`
  font-size : 1.2rem;
  font-weight : 400;
  color : ${colors.negativeText};
  margin-bottom : 0.5rem;
`