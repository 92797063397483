import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import FileExplorerPage from './pages/FileExplorerPage';
import EditorPage from './pages/EditorPage';
import styled from 'styled-components';
import colors from './contants/colors';
import packageJson from '../package.json';
import HomePage from './pages/HomePage';
import InspirationsPage from './pages/InspirationsPage';
import SessionContext from './context/SessionContext';
import { useEffect } from 'react';
import useAuthProvider from './providers/authprovider';
import LoginPage from './pages/LoginPage';

const router = createBrowserRouter([
  {path: "/", element: <HomePage />},
  {path: "/editor", element: <EditorPage />},
  {path: "/explorer", element: <FileExplorerPage />},
  {path: "/inspirations/:category", element: <InspirationsPage />}
]);

const unloggedRouter = createBrowserRouter([
  {path: "/", element: <LoginPage />},
  {path: "/editor", element: <LoginPage />},
  {path: "/explorer", element: <LoginPage />},
  {path: "/inspirations/:category", element: <LoginPage />}
])

function App() {
  const {authProvider, logged} = useAuthProvider();

  useEffect(() => {
    authProvider.init();
  }, [])

  return (
    <>
      <SessionContext value={{sessionId : Date.now().toString()}}>
        <RouterProvider router={logged ? router : unloggedRouter} />
        <Version>v{packageJson.version}</Version>
      </SessionContext>
    </>
  );
}

export default App;

const Version = styled.div`
  position: fixed;
  bottom : 0.5rem;
  right : 0.5rem;
  font-size: 1rem;
  color : ${colors.negativeText};
  z-index: 1000;
`