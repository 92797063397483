import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../../contants/colors'

interface SwitchProps {
  onIcon ?: React.ReactNode;
  offIcon ?: React.ReactNode;
  value : boolean;
  onChange : (value : boolean) => void;
}

const Switch : React.FC<SwitchProps> = (p) => {
  return (
    <Container onClick={() => p.onChange(!p.value)}>
      <Slider className={p.value ? 'on' : 'off'} />
      <IconContainer>
        {p.onIcon}
      </IconContainer>
      <IconContainer>
        {p.offIcon}
      </IconContainer>
    </Container>
  )
}

export default Switch;

const Container = styled.div`
  position : relative;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : space-between;
  height : 7rem;
  width : 14rem;
  border-radius : 3.5rem;
  background-color : ${colors.backgroundMedium};

  & svg {
    width : 4.4rem;
    height : 4.4rem;
    color : ${colors.negativeText};
  }
`

const IconContainer = styled.div`
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : center;
  height : 4rem;
  width : 4rem;
  border-radius : 50%;
  margin : 1.2rem 1.5rem;
`

const Slider = styled.div`
  position : absolute;
  height : 4.6rem;
  width : 4.6rem;
  margin : 1.2rem;
  border-radius : 50%;
  background-color : ${colors.backgroundLight};
  mix-blend-mode: exclusion;
  transition : left 0.2s ease-in-out;

  &.on {
    left : 0;
  }

  &.off {
    left : 7rem;
  }
`