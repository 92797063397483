import { Asset, Interaction, Modification, ObjectPath, useCameraState, useControlState, useUgla3dState, useUgla3dStateManager, useViewerStore, Viewer } from "@ugla-france/ugla-3d-library";
import { MouseEventHandler, TouchEventHandler, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import * as THREE from "three";
import { BiMove, BiMoveVertical } from "react-icons/bi";

import styled from "styled-components";
import colors from "../../contants/colors";
import typography from "../../contants/typography";
import PositionControls from "../atoms/PositionControls";
import { IMPORTED_ASSETS_PREFIX } from "../../contants/names";
import { bridge } from "../../pages/EditorPage";
import { BiWalk } from "react-icons/bi";
import ItemMenu from "../atoms/ItemMenu";

interface ViewerPanelProps {
  backgroundColor : string;
}

const ViewerPanel : React.FC<ViewerPanelProps> = (p) => {
  const stateManager = useUgla3dStateManager();
  const state = useUgla3dState();
  const [selection, setSelection] = useState<ObjectPath | undefined>(undefined);
  const hasPositionsGroup = (state.interactionsGroups || []).includes('positions');
  const [disableControls, setDisableControls] = useState<boolean>(false);
  const [mode, setMode] = useState<'move' | 'rotate'>('move');
  const [camera] = useCameraState();
  const [controlHeight, setControlHeight] = useState<boolean>(false);
  const handleDrop = (item : Asset) => {
    stateManager.addObject({
      id : IMPORTED_ASSETS_PREFIX + Date.now(),
      asset : item,
      tags : item.tags
    })
  }

  const [, dropRef] = useDrop(
    () => ({
      accept: 'product',
      drop: handleDrop
    }),
    []
  )

  const handleInteract = (interaction : Interaction<Asset>) => {
    if(interaction.type === 'touch') {
      setSelection(interaction.path);
    }
  }

  const handlePositions = () => {
    stateManager.addInteractionsGroup('positions');
  }

  const handleRemovePositions = () => {
    stateManager.removeInteractionsGroup('positions');
  }

  const handleClick = (e : any) => {
    e.stopPropagation();
    let obj : THREE.Object3D | null = e.object;

    while(obj && !obj.userData.uglaId) {
      obj = obj.parent;
    }


    const path = obj?.userData?.objectPath;
    const id = obj?.userData?.uglaId || '';

    if(obj && id.startsWith(IMPORTED_ASSETS_PREFIX) && path) {
      setSelection(path);
      setDisableControls(true);
    }
    else {
      setSelection(undefined);
      setDisableControls(false);
    }
  }






  ///////////////////////////////////////

  const [startPosition, setStartPosition] = useState<number[] | undefined>(undefined)
  const [lastPosition, setLastPosition] = useState<number[]>([0, 0, 0])
  const [lastRotation, setLastRotation] = useState<number[]>([0, 0, 0])
  const bridgeWasRunning = useRef<boolean>(false);
  const lastPositionMods = useRef<Modification<Asset>[] | undefined>();
  const lastRotationMods = useRef<Modification<Asset>[] | undefined>();
  const positionModId = selection?.objectId ? `${selection.objectId}-position` : '-position-';
  const rotationModId = selection?.objectId ? `${selection.objectId}-rotation` : '-rotation-';
  const [rotationTooltip, setRotationTooltip] = useState<number | undefined>(undefined);

  // Direction pointing to the camera (from the center of the camera field of view)
  let cameraRotationVector = new THREE.Vector3(0, 0, 1).applyQuaternion(new THREE.Quaternion(...camera.rotation));
  // Tangential vector to the camera direction
  let tangentialVector = new THREE.Vector3(cameraRotationVector.x, cameraRotationVector.y, cameraRotationVector.z).applyAxisAngle(new THREE.Vector3(...[0, 1, 0]), Math.PI / 2);

  const handleMouseDown : MouseEventHandler = (e) => {
    if(selection) {
      bridgeWasRunning.current = !!bridge.current?.running;
      bridge.current?.stop();

      const positionMods = stateManager.getModsById(positionModId);
      const lastPositionMod = positionMods?.[positionMods.length - 1];
      const lastPosition = lastPositionMod?.type === '3D' && lastPositionMod?.position || [0, 0, 0];
      setLastPosition(lastPosition);

      const rotationMods = stateManager.getModsById(rotationModId);
      const lastRotationMod = rotationMods?.[rotationMods.length - 1];
      const lastRotation = lastRotationMod?.type === '3D' && lastRotationMod?.rotation || [0, 0, 0];
      setLastRotation(lastRotation);

      e.preventDefault();
      e.stopPropagation();
      if(mode === "rotate") {
        setRotationTooltip(lastRotation[1]);
      }
    }
    setStartPosition([e.clientX, e.clientY]);
  }

  const handleMouseMove : MouseEventHandler = (e) => {
    if(startPosition && selection) {
      e.stopPropagation();

      const deltaX = (e.clientX - startPosition[0]) / 500;
      const deltaY = (e.clientY - startPosition[1]) / 500;

      if(mode === 'move') {
        // Direction pointing to the camera from the position of the object
        let cameraDirection = new THREE.Vector3();
        cameraDirection.subVectors(new THREE.Vector3(...camera.position), new THREE.Vector3(...lastPosition)).normalize();
        // Translation along the camera direction
        const yTranslation = cameraDirection.clone().multiplyScalar(deltaY*2);
        // Translation along the tangential vector
        const xTranslation = tangentialVector.clone().multiplyScalar(deltaX*2);
        // Total translation
        const translation = yTranslation.add(xTranslation);
      lastPositionMods.current = [{
        type : '3D',
        path : selection,
        position : [translation.x + lastPosition[0], 0, translation.z + lastPosition[2]],
        relative : true
        }]
        stateManager.resetMods(positionModId);
        stateManager.setMods(positionModId, lastPositionMods.current)
      }
      else if(mode === 'rotate') {
        const rotation = Math.floor((deltaX*100 + lastRotation[1]) / 5) * 5 % 360;
        setRotationTooltip(rotation);
        lastRotationMods.current = [{
          type : '3D',
          path : selection,
          rotation : [0, rotation, 0]
        }]
        stateManager.resetMods(rotationModId);
        stateManager.setMods(rotationModId, lastRotationMods.current)
      }
    }
  }


  const handleMouseUp = () => {
    if(bridgeWasRunning.current && !bridge.current?.running) {
      bridge.current?.start();
      if(lastPositionMods.current) {
        stateManager.resetMods(positionModId);
        stateManager.setMods(positionModId, lastPositionMods.current);
        lastPositionMods.current = undefined;
      }
      if(lastRotationMods.current) {
        stateManager.resetMods(rotationModId);
        stateManager.setMods(rotationModId, lastRotationMods.current);
        lastRotationMods.current = undefined;
      }
    }

    setStartPosition(undefined);
    setRotationTooltip(undefined);
  }

  ///////////////////////////////////////






  const handleRemove = () => {
    if(selection && selection.objectId) {
      stateManager.removeObject(selection.objectId);
    }
  }


  return (
    <Container ref={dropRef} style={{backgroundColor : p.backgroundColor}}>
      <Viewer
        onInteract={handleInteract}
        onClick={handleClick}
        selection={selection ? [selection] : undefined}
        disableControls={disableControls}
        onPointerDown={handleMouseDown}
        onPointerUp={handleMouseUp}
        onPointerLeave={handleMouseUp}
        onPointerCancel={handleMouseUp}
        onPointerMove={handleMouseMove}
        outlineColor={colors.select}
        outlineThickness={10}
        controlHeight={controlHeight}
      >
        {
          // selection ? <gridHelper position={[0, 0.01, 0]} args={[100, 1000, colors.select, colors.select]} /> : null
        }
      </Viewer>
      {/* {
        // selection ? <PositionControls path={selection} onDone={() => setSelection(undefined)}/> : null
      }
      {
        !selection && !hasPositionsGroup ? <Button onClick={handlePositions}>Déplacer</Button> : null
      }
      {
        !selection && hasPositionsGroup ? <Button onClick={handleRemovePositions}>Explorer</Button> : null
      } */}

      {
        selection ?
        <ItemMenu
          mode={mode}
          onModeChange={setMode}
          onDelete={handleRemove}
        /> : null
      }
      {
        rotationTooltip !== undefined ? <RotationTooltip>{rotationTooltip}°</RotationTooltip> : null
      }
      <ControllerTools>
        <ControllerTypeButton onClick={() => setControlHeight(!controlHeight)}>
          {controlHeight ? <BiMoveVertical size={24} /> : <BiWalk size={24} />}
        </ControllerTypeButton>
      </ControllerTools>
    </Container>
  )
}

export default ViewerPanel;

const Container = styled.div`
  width : 100%;
  height : 100%;
  position: relative;
`


const Button = styled.div`
  position : absolute;
  bottom : 1rem;
  left : 1rem;
  padding : 1rem;
  border : 1px solid ${colors.secondary};
  ${typography.label}
  cursor : pointer;
  background-color : ${colors.background};
`


const RotationTooltip = styled.div`
  position : absolute;
  bottom : 1rem;
  left : 50%;
  transform : translateX(-50%);
  padding : 1rem;
  border : 1px solid ${colors.negativeText};
  background-color : ${colors.background};
  border-radius : 0.5rem;
  font-size : 1.5rem;
`

const ControllerTools = styled.div`
  position : absolute;
  top : 0;
  right : 0;
`

const ControllerTypeButton = styled.button`
  background-color: ${colors.backgroundDark};
  color: ${colors.negativeText};
  width : 5rem;
  height : 5rem;
  border-radius : 10rem;
  border : none;
  outline : none;
  margin : 1rem;
  padding : 0;
  display : flex;
  justify-content : center;
  align-items : center;
`



// const handleTouchStart : TouchEventHandler = (e) => {
//   bridgeWasRunning.current = !!bridge.current?.running;
//   bridge.current?.stop();

//   const positionMods = stateManager.getModsById(positionModId);
//   const lastPositionMod = positionMods?.[positionMods.length - 1];
//   const lastPosition = lastPositionMod?.type === '3D' && lastPositionMod?.position || [0, 0, 0];
//   setLastPosition(lastPosition);

//   const rotationMods = stateManager.getModsById(rotationModId);
//   const lastRotationMod = rotationMods?.[rotationMods.length - 1];
//   const lastRotation = lastRotationMod?.type === '3D' && lastRotationMod?.rotation || [0, 0, 0];
//   setLastRotation(lastRotation);

//   e.stopPropagation();
//   if(e.touches.length !== 1) {return;}
//   setStartPosition([e.touches[0].clientX, e.touches[0].clientY]);
// }


// const handleTouchMove : TouchEventHandler = (e) => {
//   e.stopPropagation();
//   if(e.touches.length !== 1) {return;}

//   if(startPosition && selection) {
//     const deltaX = (e.touches[0].clientX - startPosition[0]) / 500;
//     const deltaY = (e.touches[0].clientY - startPosition[1]) / 500;


//     if(mode === 'move') {
//       lastPositionMods.current = [{
//         type : '3D',
//         path : selection,
//         position : [deltaX + lastPosition[0], 0, deltaY + lastPosition[2]],
//         relative : true
//       }]
//       stateManager.resetMods(positionModId);
//       stateManager.setMods(positionModId, lastPositionMods.current)
//     }
//     else if(mode === 'rotate') {
//       lastRotationMods.current = [{
//         type : '3D',
//         path : selection,
//         rotation : [0, deltaX*100 + lastRotation[1], 0]
//       }]
//       stateManager.resetMods(rotationModId);
//       stateManager.setMods(rotationModId, lastRotationMods.current)
//     }
//   }
// }
