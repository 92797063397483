import styled from "styled-components";
import colors from "../../../contants/colors";
import Input from "../../controls/TextInput";
import Button from "../../controls/Button";
import { BiBulb, BiSolidBulb, BiSolidMoon, BiSolidSun } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import Switch from "../../controls/Switch";
import IconRadioGroup from "../../controls/IconRadioGroup";

interface RenderSubpanelProps {
  backgroundColor : string;
  setBackgroundColor : (color : string) => void;
}

const RenderSubpanel : React.FC<RenderSubpanelProps> = (p) => {
  const stateManager = useUgla3dStateManager();
  const [daylight, setDaylight] = useState(true);
  const [ceilingLights, setCeilingLights] = useState(true);
  const [ceilingLightsTemperature, setCeilingLightsTemperature] = useState(1);

  const handleDaylight = (value : boolean) => {
    setDaylight(value);
    stateManager.setMods('sun', [{
      type : 'light',
      path : {objectId : 'ambient', path : []},
      intensity : value ? 2 : 0.1
    }])

    p.setBackgroundColor(value ? '#FFFFFF' : '#0c0c3a');
  }

  const handleCeilingLights = (value : boolean) => {
    setCeilingLights(value);
    stateManager.setMods('ceiling', [{
      type : 'light',
      path : {objectId : 'point', path : []},
      intensity : value ? 4 : 0.0001,
    }])
  }

  const handleCeilingLightsTemperature = (value : number) => {
    setCeilingLightsTemperature(value);
    stateManager.setMods('ceilingTemperature', [{
      type : 'light',
      path : {objectId : 'point', path : []},
      color : ['#fde9aa', '#FFFFFF', '#dae2ff'][value]
    }])
  }


  return (
    <Container>
      <LightContainer>
        <Label>Soleil</Label>
        <LightControlContainer>
          <Switch
            value={daylight}
            onChange={handleDaylight}
            onIcon={<BiSolidSun />}
            offIcon={<BiSolidMoon />}
          />
        </LightControlContainer>
      </LightContainer>
      <LightContainer>
        <Label>Plafoniers</Label>
        <LightControlContainer>
          <IconRadioGroup
            options={[
              {label : '4000K', icon : <BiBulb />, offIcon : <BiSolidBulb />, color : '#FFE18D'},
              {label : '6000K', icon : <BiBulb />, offIcon : <BiSolidBulb />, color : '#ffffff'},
              {label : '8000K', icon : <BiBulb />, offIcon : <BiSolidBulb />, color : '#9dd3f9'}
            ]}
            value={ceilingLightsTemperature}
            onChange={handleCeilingLightsTemperature}
          />
          <Switch
            value={ceilingLights}
            onChange={handleCeilingLights}
            onIcon={<BiBulb />}
          />
        </LightControlContainer>
      </LightContainer>
    </Container>
  )
}

export default RenderSubpanel;

const Container = styled.div`
  display : flex;
  width: 100%;
  height: 100%;
  background-color: ${colors.backgroundDark};
  border : none;
`
const LightContainer = styled.div`
  display : flex;
  flex-direction : column;
  border : 0.05rem solid ${colors.backgroundLight};
  border-radius : 2rem;
  padding : 1rem 2rem;
  margin : 2rem 3rem;
`

const Label = styled.div`
  font: normal normal normal 1.8rem/2.4rem Roboto;
  color : ${colors.negativeText};
`

const LightControlContainer = styled.div`
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  flex : 1;
`